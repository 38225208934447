<template>
  <div class="Requisition">
    <!-- Page options -->
    <div class="PageOptions">
      <Button
        :text="mixWB('BACK')"
        :fullWidth="false"
        :path="{ name: 'ResultOverview', params: { screeningID: screeningID } }"/>
      <Button
        class="SendButton"
        :text="mixWB('PDF_SAVE')"
        :fullWidth="false"
        @button-click="saveRequisition" />
    </div>

    <!-- Pickup notice -->
    <div
      class="PickupCallNotice">
      {{ mixWB('REQUISITION_PICKUP_NOTICE') }}
    </div>

    <!-- Result options -->
    <Options
      :selectedDeliveryOption="selectedDeliveryOption"
      :customOfferNumber="customOfferNumber"
      :onDeliveryOptionChange="onDeliveryOptionChange"
      :onCustomOfferNumberUpdate="onCustomOfferNumberUpdate" />

    <!-- Pages - Desktop -->
    <div class="PageList">
      <!-- First page -->
      <div class="Page">
        <div class="InnerPage">
          <Header />
          <BaseInfo
            :total="mutatedSamples.total"
            :selectedDeliveryOption="selectedDeliveryOption"
            :customOfferNumber="customOfferNumber" />
          <SamplesTable
            :samples="mutatedSamples.firstPage"
            :totals="mutatedSamples.totals" />
          <Footer
            :currentPage="1"
            :totalPages="1 + mutatedSamples.extraPages.length" />
        </div>
      </div>

      <!-- Extra pages -->
      <div
        v-for="(sampleList, index) in mutatedSamples.extraPages"
        :key="index"
        class="Page">
        <div class="InnerPage">
          <SamplesTable
            :samples="sampleList"
            :totals="mutatedSamples.totals" />
          <Footer
            :currentPage="index + 2"
            :totalPages="1 + mutatedSamples.extraPages.length" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Options from '@/components/PDF/Requisition/Options.vue'
import Button from '@/components/Buttons/Button.vue'
import Header from '@/components/PDF/Requisition/Header.vue'
import BaseInfo from '@/components/PDF/Requisition/BaseInfo.vue'
import { checkToAddPCBToType } from '@/globals/javascript/_util/samples'
import { sortBy } from 'lodash-es'
import SamplesTable from '@/components/PDF/Requisition/SamplesTable.vue'
import Footer from '@/components/PDF/Requisition/Footer.vue'

export default {
  name: 'Requisition',
  data() {
    return {
      maxSamplesFirstPage: 15,
      maxSamplesRestOfPages: 32,
      hasErrorOnSend: false,
      selectedDeliveryOption: '5-days-17',
      customOfferNumber: '',
    }
  },
  computed: {
    ...mapGetters([
      'screeningSamples',
      'screeningTypes',
      'screeningPCBScreenings',
      'caseNumber',
      'personalSamplingID',
      'currentScreeningData',
      'allUsers',
      'currentUser',
      'currentAccount',
    ]),
    screeningID() {
      return this.$route.params.screeningID
    },
    screener() {
      return this.allUsers.find((x) => x.id === this.currentScreeningData.userID)
    },
    mutatedSamples() {
      let tempSamples = []
      const data = {
        firstPage: [],
        extraPages: [],
        totals: {},
        total: 0,
      }

      this.screeningSamples.forEach((sample) => {
        if (sample.status !== 'done') {
          return
        }

        let type = null
        if (sample.typeID) {
          type = this.screeningTypes.find((x) => x.id === sample.typeID)
        }
        // Pre 1.14.0
        else {
          type = this.screeningTypes.find((x) => x.id === sample.id)
        }

        if (!type) {
          return
        }

        let sampleIDs = []
        if (sample.kindOfSample === 'material') {
          sampleIDs = type.samples.sampleIDs
        }
        if (sample.kindOfSample === 'coating') {
          sampleIDs = type.coating.samples.sampleIDs
        }
        if (sample.kindOfSample === 'underlining') {
          sampleIDs = type.underlining.samples.sampleIDs
        }
        if (!sampleIDs.length) {
          return
        }

        const item = {
          title: '',
          sampleNumber: sample.sampleNumber,
          sampleIDs,
        }

        const addPCBAnalysis = checkToAddPCBToType(
          type,
          this.screeningPCBScreenings,
          sample.kindOfSample,
        )
        if (addPCBAnalysis) {
          item.sampleIDs.push('ST-4')
        }

        item.title = `P${
          sample.sampleNumber
        } - ${
          type.getTitle({ category: true, details: true })
        }${
          sample.kindOfSample === 'coating' ? ` (${ this.mixWB('COATING') })` : ''
        }${
          sample.kindOfSample === 'underlining' ? ` (${ this.mixWB('UNDERLINING') })` : ''
        }${
          type.description ? ` - ${ type.description }` : ''
        }`

        // Update totals
        item.sampleIDs.forEach((id) => {
          if (typeof data.totals[id] === 'undefined') {
            data.totals[id] = 1
          }
          else {
            data.totals[id] += 1
          }
        })

        tempSamples.push(item)
      })

      tempSamples = sortBy(tempSamples, ['sampleNumber'])

      data.total = tempSamples.length

      // Get samples to show on first page
      data.firstPage = tempSamples.splice(0, this.maxSamplesFirstPage)

      if (!tempSamples.length) {
        return data
      }

      // Get samples to show on extra pages
      while (tempSamples.length) {
        data.extraPages.push(tempSamples.splice(0, this.maxSamplesRestOfPages))
      }

      return data
    },
  },
  methods: {
    setPageTitle() {
      if (this.personalSamplingID) {
        document.title = this.personalSamplingID
        return
      }
      if (this.caseNumber) {
        document.title = this.caseNumber
        return
      }
    },
    setDataOnLoad() {
      const { requisition } = this.currentScreeningData

      if (!requisition) {
        return
      }

      if (requisition.deliveryDayOptions) {
        this.deliveryDayOptions = requisition.deliveryDayOptions
      }

      if (requisition.customOfferNumber) {
        this.customOfferNumber = requisition.customOfferNumber
      }
    },
    onDeliveryOptionChange({ value }) {
      this.selectedDeliveryOption = value
    },
    onCustomOfferNumberUpdate({ value }) {
      this.customOfferNumber = value
    },
    async saveRequisition() {
      // For pro v1 we emulate CTRL+P
      window.print()
    },
  },
  components: {
    Button,
    Options,
    Header,
    BaseInfo,
    SamplesTable,
    Footer,
  },
  created() {
    this.setDataOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .Requisition
    padding 20px
    +below($tablet)
      padding 15px
    +below($phablet)
      padding 10px

  .PageOptions
    max-width 794px
    width 100%
    margin 0px auto 10px
    display flex
    justify-content space-between
    align-items flex-start
    .ErrorTextWrap
      padding 0 10px
      >>> .ErrorText
        margin 0
    .SendButton
      width auto

  .PickupCallNotice
    max-width 794px
    width 100%
    margin 0px auto 20px
    padding 10px
    background-color white
    font-weight bold
    box-shadow $box_shadow_1
    +below($tablet)
      margin-bottom 10px

  .Page
    page()

  .InnerPage
    innerPage()
</style>

<style lang="stylus">
  .PageRequisition
    background-color $color_grey_lighter
    @media print
      .Requisition
        margin 0
        padding 0
        > *:not(.PageList)
          display none

</style>

<template>
  <div
    v-if="isInitialised"
    class="BaseInfo">
    <div class="Top">
      <!-- Company info -->
      <div class="Block">
        <table>
          <tr>
            <th colspan="2">{{ mixWB('REQUESTER_AND_INVOICE_SENT_TO') }}</th>
          </tr>
          <tr>
            <td>{{ mixWB('COMPANY_NAME') }}</td>
            <td>{{ currentAccount.name }}</td>
          </tr>
          <tr>
            <td>{{ mixWB('ADDRESS') }}</td>
            <td>{{ currentAccount.address }}</td>
          </tr>
          <tr>
            <td>{{ mixWB('POSTAL_CODE_AND_CITY') }}</td>
            <td>{{ currentAccount.postalCode }} {{ currentAccount.city }}</td>
          </tr>
          <tr>
            <td>{{ mixWB('CONTACT_PERSON') }}</td>
            <td>{{ currentAccount.contactPerson.name }}</td>
          </tr>
          <tr>
            <td>{{ mixWB('PHONE_NUMBER') }}</td>
            <td>{{ currentAccount.contactPerson.phoneNumber }}</td>
          </tr>
          <tr>
            <td>{{ mixWB('EMAIL') }}</td>
            <td>{{ currentAccount.contactPerson.email }}</td>
          </tr>
          <tr>
            <td>{{ mixWB('OFFER_NUMBER') }}</td>
            <td>{{ offerNumberText }}</td>
          </tr>
          <tr>
            <th colspan="2">{{ mixWB('DELIVERY') }}</th>
          </tr>
          <tr>
            <td
              v-html="deliveryDaysText"
              colspan="2" />
          </tr>
        </table>
      </div>

      <!-- Screener info -->
      <div class="Block">
        <table>
          <tr>
            <th colspan="2">{{ mixWB('SAMPLER') }}</th>
          </tr>
          <tr>
            <td>{{ mixWB('NAME') }}</td>
            <td>{{ screener.fullName }}</td>
          </tr>
          <tr>
            <td>{{ mixWB('PHONE_NUMBER') }}</td>
            <td>{{ mixFormatPhoneNumber(screener.phoneNumber) }}</td>
          </tr>
          <tr>
            <td>{{ mixWB('EMAIL') }}</td>
            <td>{{ mixFormatPhoneNumber(screener.email) }}</td>
          </tr>
          <tr>
            <td>{{ mixWB('WITHDRAWAL_DATE') }}</td>
            <td>{{ screeningDate }}</td>
          </tr>
          <tr v-if="personalSamplingID">
            <td>{{ mixWB('CASE_NUMBER') }}</td>
            <td>{{ personalSamplingID }}</td>
          </tr>
          <tr v-else>
            <td>{{ mixWB('CASE_NUMBER') }}</td>
            <td>{{ caseNumber }}</td>
          </tr>
          <tr>
            <td>{{ mixWB('CASE_NAME') }}</td>
            <td>{{ caseName }}</td>
          </tr>
          <tr>
            <th colspan="2">{{ mixWB('TOTAL_NUMBER_OF_SAMPLES') }}</th>
          </tr>
          <tr>
            <td colspan="2">{{ total === 1 ? mixWB('1_PIECE') : mixWB('X_PCS', [total]) }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseInfo',
  props: {
    total: {
      type: Number,
      required: true,
    },
    selectedDeliveryOption: {
      type: String,
      required: true,
    },
    customOfferNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isInitialised: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'currentScreeningData',
      'caseNumber',
      'personalSamplingID',
      'caseName',
      'allUsers',
      'currentAccount',
    ]),
    screener() {
      return this.allUsers.find((x) => x.id === this.currentScreeningData.userID)
    },
    screeningDate() {
      if (!this.currentScreeningData.screeningEndTime) {
        return '-'
      }

      return this.mixGetDate(this.currentScreeningData.screeningEndTime, 'LL')
    },
    offerNumberText() {
      const queryValue = this.$route.query.customOfferNumber
      const queryExists = this.$route.query.hasOwnProperty('customOfferNumber')

      if (queryExists) {
        return queryValue || this.currentAccount.euroFinsNumber
      }

      if (this.customOfferNumber) {
        return this.customOfferNumber
      }

      return this.currentAccount.euroFinsNumber
    },
    deliveryDaysText() {
      // Display the value provided via props, BUT if the pdf generator (cloud function) is viewing
      // this, it is not stored yet and comes from a query parameter in the URL
      const queryValue = this.$route.query.delivery

      const value = queryValue ?? this.selectedDeliveryOption

      if (value === '1-day-8') {
        return `<b>${ this.mixWB('DELIVERY_IN_1_WORKING_DAY_AT_X_O_CLOCK', ['8']) }</b>`
      }
      if (value === '1-day-12') {
        return `<b>${ this.mixWB('DELIVERY_IN_1_WORKING_DAY_AT_X_O_CLOCK', ['12']) }</b>`
      }
      if (value === '1-day-17') {
        return `<b>${ this.mixWB('DELIVERY_IN_1_WORKING_DAY_AT_X_O_CLOCK', ['17']) }</b>`
      }
      if (value === '2-days-17') {
        return this.mixWB('DELIVERY_IN_X_WORKING_DAYS_AT_X_O_CLOCK', ['2', '17'])
      }
      if (value === '3-days-17') {
        return this.mixWB('DELIVERY_IN_X_WORKING_DAYS_AT_X_O_CLOCK', ['3', '17'])
      }
      if (value === '5-days-17') {
        return this.mixWB('DELIVERY_IN_X_WORKING_DAYS_AT_X_O_CLOCK', ['5', '17'])
      }
      return this.mixWB('DELIVERY_IN_X_WORKING_DAYS_AT_X_O_CLOCK', ['5', '17'])
    },
  },
  created() {
    const waitForLoad = setInterval(() => {
      if (
        !this.currentScreening
        || !this.currentScreeningData
        || !this.caseNumber
        || !this.allUsers.length
      ) {
        return
      }

      clearInterval(waitForLoad)

      this.isInitialised = true
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .BaseInfo
    display block
    margin 0px -10px 20px

  .Top
    display flex
    flex-wrap wrap
    +below($tablet)
      flex-direction column

  .Block
    flex-grow 2
    flex-basis 0
    margin 0 10px 20px

  table
    width 100%
    border-collapse collapse
    th
      text-align left
      background-color $color_grey_lighter
      text-transform uppercase
    th, td
      padding 5px
    td
      &:first-child
        white-space nowrap
      +below($tablet)
        font-size 0.75rem

  table, tr, th, td
    border 1px solid $color_grey_light
    font-size 0.875rem
</style>

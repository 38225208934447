<template>
  <img
    v-if="src"
    :src="src"
    :class="className"
    @load="onImageLoaded"
    @error="onImageError" />
</template>

<script>
import { PDFTracker } from '@/globals/javascript/_util/PDFTracker'

export default {
  name: 'PDFImage',
  props: {
    path: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      src: null,
    }
  },
  methods: {
    onImageLoaded() {
      PDFTracker.loaded(this.path)
    },
    onImageError() {
      PDFTracker.failed(this.path)
    },
  },
  mounted() {
    PDFTracker.track(this.path)
    this.mixGetDownloadURL({
      path: this.path,
    }).then((url) => {
      this.src = url
    }).catch(() => {
      PDFTracker.failed(this.path)
    })
  },
  destroyed() {
    PDFTracker.unTrack(this.path)
  },
}
</script>

<template>
  <div class="SamplesTable">

    <!-- Header part -->
    <table class="HeaderPart">
      <tr class="TotalRow">
        <td/>
        <td
          v-for="sample in sampleTypesAsArray"
          :key="sample.id">
          {{ totals[sample.id] || 0 }}
        </td>
      </tr>
      <tr class="SampleTitleRow">
        <td/>
        <td
          v-for="sample in sampleTypesAsArray"
          :key="sample.id">
          <span>{{ mixWB(sample.labInfo.wordbook) }}</span>
          <span>{{ sample.labInfo.code }}</span>
        </td>
      </tr>
    </table>

    <!-- Body part -->
    <div class="TableBodyWrap">
      <!-- Sample numbers -->
      <table class="BodyNumbers">
        <tr
          v-for="item in samples"
          :key="item.sampleNumber">
          <td>{{ item.sampleNumber }}</td>
        </tr>
      </table>

      <!-- Sample titles -->
      <table class="BodyTitles">
        <tr
          v-for="item in samples"
          :key="item.sampleNumber">
          <td>{{ item.title }}</td>
        </tr>
      </table>

      <!-- Sample tests -->
      <table class="BodyTests">
        <tr
          v-for="item in samples"
          :key="item.sampleNumber">
          <td
            v-for="sample in sampleTypesAsArray"
            :key="sample.id">
            {{ item.sampleIDs.includes(sample.id) ? 'X' : '' }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SamplesTable',
  props: {
    samples: {
      type: Array,
      required: true,
    },
    totals: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'sampleTypesAsArray',
    ]),
  },
}
</script>

<style lang="stylus" scoped>
  .SamplesTable
    display block
    +below($tablet)
      margin-bottom 30px

  .HeaderPart
    width 100%
    border-bottom none
    tr
      &:first-child
        background-color rgba($color_grey_light, 0.3)
      &:last-child
        border-bottom none
    td
      width 36px
      border-bottom none
      &:first-child
        width auto
      &:nth-child(2)
        background-color rgba($color_yellow_lightest, 0.7)
      &:nth-child(3)
        background-color rgba($color_salmon_lightest, 0.7)
      &:nth-child(4)
        background-color rgba($color_primary_lightest, 0.6)
      &:nth-child(5)
        background-color rgba($color_purple_lightest, 0.5)
      &:nth-child(6)
        background-color rgba($color_info_bg, 0.6)

  .TableBodyWrap
    display flex

  .BodyNumbers
    width 27px
    border-right none
    tr
      border-right none
    td
      border-right none
    +below($tablet)
      td
        font-size 0.875rem
    +below($phablet)
      display none

  .BodyTitles
    flex-grow 2
    border-right none
    tr
      border-right none
    td
      border-right none
      text-align left
      white-space nowrap
      text-overflow ellipsis
      overflow hidden
      max-width 1px
    +below($tablet)
      td
        font-size 0.875rem
    +below($phablet)
      td
        font-size 0.665rem

  .BodyTests
    td
      width 36px
      &:nth-child(1)
        background-color rgba($color_yellow_lightest, 0.7)
      &:nth-child(2)
        background-color rgba($color_salmon_lightest, 0.7)
      &:nth-child(3)
        background-color rgba($color_primary_lightest, 0.6)
      &:nth-child(4)
        background-color rgba($color_purple_lightest, 0.5)
      &:nth-child(5)
        background-color rgba($color_info_bg, 0.6)
    +below($tablet)
      td
        font-size 0.875rem
    +below($phablet)
      td
        font-size 0.665rem

  .BodyNumbers,
  .BodyTitles,
  .BodyTests
    tr
      &:nth-child(2n-1)
        background-color rgba($color_grey_lighter, 0.4)

  .SampleTitleRow
    td
      width 36px
      max-width 36px
      height 110px
      text-align left
      white-space nowrap
      span
        display block
        &:first-child
          font-size 0.63rem
          transform rotate(-90deg) translateX(-40px) translateY(-7px)
        &:last-child
          font-size 0.5rem
          transform rotate(-90deg) translateX(-27px) translateY(7px)

  table
    border-collapse collapse
    td
      padding 5px
      text-align center

  table, tr, th, td
    border 1px solid $color_grey_light
    font-size 0.875rem
</style>

<template>
  <div class="Header">
    <span class="Date">{{ currentDate }}</span>

    <div class="LogoWrap">
      <PDFImage :path="currentAccount.images.mainLogo" />
    </div>

    <div class="TitleWrap">
      <span class="Title">{{ mixWB('ANALYSIS_REQUISITION_AND_MATERIAL_SAMPLES') }}</span>
      <span class="SubTitle">{{ mixWB('ATT_VBM_LABORATORY') }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PDFImage from '@/components/PDFImage.vue'

export default {
  name: 'Header',
  computed: {
    ...mapGetters([
      'currentAccount',
    ]),
    currentDate() {
      const today = new Date()

      return `${ today.getDate() }/${ today.getMonth() + 1 }/${ today.getFullYear() }`
    },
  },
  components: {
    PDFImage,
  },
}
</script>

<style lang="stylus" scoped>
  .Header
    position relative
    display block
    margin-bottom 20px

  .Date
    position absolute
    top 0
    right 0
    color $color_grey_light
    font-size 0.875rem

  .LogoWrap
    padding 5px 0px 15px
    img
      display block
      margin 0px auto
      width 160px

  .TitleWrap
    span
      display block
      text-align center
    .Title
      font-size 1.5rem
      text-transform uppercase
    .SubTitle
      color $color_grey
      font-size 0.875rem
    +below($tablet)
      .Title
        font-size 1.25rem
    +below($phablet)
      .Title
        font-size 0.875rem
      .SubTitle
        font-size 0.665rem
</style>

<template>
  <div class="Options">
    <div class="Option">
      <span class="SectionTitle">{{ mixWB('SELECT_NUMBER_OF_DAYS_FOR_DELIVERY') }}</span>
      <Dropdown
        name="delivery-days"
        :options="deliveryOptions"
        :value="selectedDeliveryOption"
        backgroundColor="white"
        @on-update="onDeliveryOptionChange" />
      <span class="ExtraText">{{ mixWB('1_DAY_DELIVERY_OBS_TEXT') }}</span>
    </div>

    <div class="Option">
      <span class="SectionTitle">{{ mixWB('OFFER_NUMBER') }}</span>
      <InputField
        name="offer-number"
        backgroundColor="white"
        :value="customOfferNumber"
        :placeholder="currentAccount.euroFinsNumber"
        @on-update="onCustomOfferNumberUpdate" />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/FormElements/Dropdown.vue'
import { mixWB } from '@/globals/javascript/_util/mixins'
import InputField from '@/components/FormElements/InputField.vue'
import { mapGetters } from 'vuex'

export const DELIVERY_OPTIONS = [
  { value: '1-day-8', name: mixWB('1_DAY_X_O_CLOCK', ['8']) },
  { value: '1-day-12', name: mixWB('1_DAY_X_O_CLOCK', ['12']) },
  { value: '1-day-17', name: mixWB('1_DAY_X_O_CLOCK', ['17']) },
  { value: '2-days-17', name: mixWB('X_DAYS_X_O_CLOCK', ['2', '17']) },
  { value: '3-days-17', name: mixWB('X_DAYS_X_O_CLOCK', ['3', '17']) },
  { value: '5-days-17', name: mixWB('X_DAYS_X_O_CLOCK', ['5', '17']) },
]

export default {
  name: 'Options',
  props: {
    selectedDeliveryOption: {
      type: String,
      required: true,
    },
    customOfferNumber: {
      type: String,
      required: true,
    },
    onDeliveryOptionChange: {
      type: Function,
      required: true,
    },
    onCustomOfferNumberUpdate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      deliveryOptions: DELIVERY_OPTIONS,
    }
  },
  computed: {
    ...mapGetters([
      'currentAccount',
    ]),
  },
  components: {
    Dropdown,
    InputField,
  },
}
</script>

<style lang="stylus" scoped>
  .Options
    position relative
    display flex
    justify-content space-between
    flex-wrap wrap-reverse
    max-width 794px
    width 100%
    min-height 100px
    padding 20px 10px
    background-color #fff
    margin 0px auto 20px
    box-shadow $box_shadow_1

  .Option
    width calc(50% - 20px)
    min-width 240px
    max-width: calc(100% - 20px);
    flex-grow 1
    margin-left 10px
    margin-right 10px
    span
      display block
    .SectionTitle
      display block
      margin-bottom 10px
      border-bottom 1px solid $color_grey_lighter
      font-size 0.875rem
      text-transform uppercase
      padding-bottom 3px
    .ExtraText
      font-size 0.875rem
      font-weight bold
</style>
